import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    detection: {
      order: ["querystring", "cookie", "navigator"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      caches: ["cookie"],
    },
    detectionOptions: {
      // Specify the language for Turkey (Turkish)
      order: ["path", "cookie", "navigator"],
      lookupPath: "lng",
      lookupFromPathIndex: 0,
      caches: ["cookie"],
      cookieMinutes: 60,
      cookieDomain: "yourdomain.com",
      cookieSecure: false,
      cookieName: "i18next",
      checkWhitelist: true,
      whiteList: ["tr"],
    },
    backend: {
      // Specify the language for Arab countries (Arabic)
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      // ...
    },
    resources: {
      en: {
        translation: {
          navbar: {
            1: "Corporation",
            2: "Contact",
            3: "About",
            4: "Our Services",
            5: "Medical Tourism In Turkey",
            6: "Hair Transplant",
            7: "Medical Aesthetic",
            8: "Dental Aesthetic",
            9: "Plastic Surgeries",
            10: "Contact Us",
            11: "Contracted Institutions",
            12: "Dr. Dilek Akın Aqrah Clinic",
            13: "Special Avangart Mouth and Dental Clinic",
            14: "Dr. Selahattin Tulunay Clinic",
            15: "Transportation",
            16: "Accommodations",
            17: "Flight Services",
            19: "Insurance Services",
            20: "Translation",
            21: "Treatments",
          },
          pageHeaders: {
            1: "Our Accommodation Services",
            2: "Our Translation Services",
            3: "Our Transportation Services",
            4: "Our Flight Services",
            5: "Our Insurance Services",
            6: "Live like a native of Turkey with our different accommodation options.\n You will feel at home with health. Our business is 4 and 5-star city hotels, according to your preference, where you and your relatives will stay during your treatment.\n Our agency is a member of the Association of Turkish Travel Agencies (TÜRSAB).\n We have agreements with hotels in the city center that are easily accessible, close to shopping malls, and to many cultural and historical centers. We can offer you special discounted hotel options for your needs in the room type and comfort you desire.\n",
            7: "During your treatment process in Turkey, interpreting services are provided by our assistants assigned to you during your meetings with the team that will carry out your treatment, during your transfer and accommodation processes.\n",
            8: "Welcoming patients and their relatives from different countries and cities at airports; and transfer services with special shuttles and vehicles for transportation to the planned routes.\n Due to the health conditions of our patients, full-fledged ambulance support is also provided in the said transfer services when necessary.\n You are accompanied by the Company's travel officials to fully respond to your possible demands and needs during your journey, which will start with your meeting at the airport following your arrival in our country.\n",
            9: "Our organization, which is a member of the International Air Transport Association (IATA), tickets all domestic and foreign airline companies. It searches for you with the most suitable flight alternatives for your budget and travel plan.\n Compares the air ticket prices of all airlines according to the date and route you choose for your flight; offers you the most affordable flight ticket alternatives.\n With our professional travel consultancy services, you can reach our call center and buy the most economical flight tickets of all airline companies safely and effortlessly.\n With our special consultancy service, our travel authorities follow your option periods on your behalf; They provide you with fast support in your reservation change and cancellation requests.\n",
            10: "Our travel consultants are ready to answer your questions about insurance services and provide the necessary support to eliminate your financial worries before your travel, where human health is the most important issue.\n If you have international health insurance or if your treatment expenses will be covered by a state; if you inform the authorities of our company before your trip, the necessary planning will be made by us for the complete execution of all transactions. If you do not have any insurance coverage, upon your request, it will secure all your patients and their relatives during your trip to our country.\n The most comprehensive and economical private insurance policies will be presented to you by our consultants with detailed information.\n",
          },
          header1: {
            1: "Call Us +905550333444",
          },
          header3: {
            1: "+90 555 033 34 44",
            2: "24/7 Availability",
            3: "24/7 Availability on WhatsApp",
            4: "Guide",
            5: "FREE CONSULTATION",
            6: "OUR CLINICS",
          },
          footerHeaders: {
            1: "Our Services",
            Accommodations: "Accommodations",
            Translation: "Translation",
            Flight: "Flight",
            Transportation: "Transportation",
            Insurance: "Insurance",
          },
          footerDetails: {
            0: "Home",
            1: "Categories",
            2: "Why Turkey?",
            3: "Contact Us",
            4: "Free Consultation",
            5: "About Us",
          },
          footerContact: {
            call: "+90 555 033 34 44\n ",
            email: "deniz@deniznedimoglu.com\n ",
            location:
              "Merkez mahallesi \n Dilruba Çıkmazı \n Sokak No 16 d 4 \nkagithane istanbul\n",
            message: "+90 555 033 34 44\n ",
          },
          HomeCard: {
            title1: "Medical Aesthetic ",
            titel2: "Hair Treatments",
            titel3: "Plastic Surgeries",
            title4: "We Are \n **Transforming** \n Lives",
            title5: "The \n **Confidence** \n of a New You",
            title6: "Where Science Meets \n **Art** \n",
            title7: "Your Bueaty Will Be In Good Hands",
            title8: "Deniz Nedimoğlu Health Tourism",
          },
          HomePercentage: {
            1: "Free online Consultations",
            2: "Accredited Hospitals & Clinics",
            3: "Treated Patients",
            4: "Positive Feedback",
          },
          homeCategories: {
            header: "WE HELP IN EVERY ASPECT",
            1: "Fast Response",
            2: "Get quick responses to your inquiries, often within minutes.",
            3: "Free Consultation",
            4: "Speak directly with a Professor Doctor who specializes in your case.",
            5: "24/7 Support",
            6: "We're available around the clock to support you whenever you need us.",
            7: "Accommodation",
            8: "We'll greet you at the airport and accompany you to your destination.",
            9: "Translation",
            10: "We speak your language and provide translation services as needed.",
            11: "Satisfied Patients",
            12: "Over 99% of our patients report high satisfaction with their experience.",
            13: "Best Doctors",
            14: "Our highly educated and internationally renowned doctors and professors ensure the best possible care for you.",
            15: "Online Appointment",
            16: "Schedule your appointment with ease from the comfort of your own home.",
            17: "Best Options",
            18: "Learn about the best treatment options for your case, both in your own country and worldwide.",
            19: "Best Hospitals",
            20: "Choose from among 300 accredited facilities across Turkey for your medical needs.",
          },
          skinPage: {
            Header1: "How Do We Care About Your Skin?",
            Header2: "The Process:",
            Header3: "Steps:",
            Text1:
              "Medical skin care is a process that aims to restore the vitality of the skin by using skin care products, and to purify the skin from factors that make it look dull, such as oil, black spots, and makeup residues.\n The process includes skin analysis to determine its needs, cleaning of the skin, peeling, and disinfection.\n The skin is then moisturized and additional treatments may be applied as needed.",
            Text2: {
              step1:
                "The skin care process begins with an analysis of the skin to determine its needs.\n",
              step2: "Then cleaned\n",
              step3: "The skin is exfoliated using special products\n",
              step4: "Then the Pores will be open up using vapor therapy\n",
              step5:
                "Deep disinfection is done to remove impurities and care is taken to remove any remaining oil or makeup residues\n",
              step6: "The process ends with moisturizing and a care mask\n",
            },
            Services: {
              Services: "Some Of The Medical Aesthetic Services",
              1: "ACNE & SCARRING TREATMENTS",
              2: "PROFESSIONAL SKIN CARE",
              3: "GEL FACE MASK",
              4: "CARBON PEELING",
              5: "DEPIGMENTATION TREATMENT",
              6: "DEEP SKIN CLEANSING",
              7: "NEVUS TREATMENT",
              8: "LASER TATTOO REMOVAL",
              9: "DERMA THERAPY",
              10: "SKIN PEELS",
              11: "SKIN ANALYSIS",
              12: "FRACTIONAL LASER STRETCH MARKS TREATMENT",
            },
          },
          hairPage: {
            header1: "Hair Transplant Treatments",
            header2: "Our Services",
            1: "Hair Transplant For Women",
            text1:
              "Hair Transplantation without shaving is a method developed for this purpose.\n Hair transplantation may be performed especially on the front side of the head called the front shed.\n In particular, women might have renounced from hair transplantation due to the necessity of hair shaving or most patients do not want this procedure to be noticed. Unshaven Hair Transplantation may be done among your existing long hair and offers you a treatment that does not leave the social life.",
            2: "Hair Transplant For Men",
            text2:
              "This technique is a hair transplantation method which is based on transferring hair roots that were taken from the nape area of the human one by one to areas where there is no hair.\n Hairs which were taken from any region (back, chest, legs, etc.) can also be transferred.",
            3: "Beard Transplant",
            text3:
              "Beard transplant might be operated when there are scars of acne or different types of damage on the face to cover these scars.\n The above-mentioned scars might develop in the chin, cheeks, mustache, or sideburn area.\n Removing the visibility of the scars is possible with this method.",
          },
          PlasticSurguries: {
            header1: "PLASTIC SURGERIES",
            header2: "Our Services",
            1: "Facial Aesthetics",
            text1:
              "Botox: Botox is a neuromodulator that can be used to smooth out wrinkles and fine lines by temporarily relaxing the muscles that cause them. It is commonly used to treat frown lines, crow's feet, and forehead lines.\n Dermal fillers: Dermal fillers are injectable gels that are used to restore volume and fullness to the face. They can be used to plump up lips, cheeks, and hollows under the eyes, as well as to smooth out wrinkles and fine lines.\n Microdermabrasion: Microdermabrasion is a non-invasive treatment that uses a special tool to exfoliate the top layer of skin. It can help to reduce the appearance of fine lines and wrinkles, improve skin texture and tone, and reduce the appearance of acne scars and sun damage.\n Platelet-rich plasma (PRP) therapy: PRP therapy involves using the patient's own blood, which is processed to isolate the platelets and growth factors. These are then injected back into the patient's face to stimulate collagen production and improve skin quality.",
            2: "Body Aesthetics",
            text2:
              "A variety of cosmetic procedures that focus on improving the appearance of the body.\n At our clinic, we offer body aesthetics services, such as liposuction, tummy tucks, and body contouring. Liposuction is a procedure that removes excess fat from specific areas of the body, such as the abdomen, hips, and thighs. A tummy tuck, on the other hand, removes excess skin and fat from the abdominal area, resulting in a flatter, more toned appearance.\n Body contouring procedures are designed to reshape the body and include treatments like CoolSculpting, which uses controlled cooling to eliminate fat cells, and SculpSure, which uses laser technology to target and destroy fat cells. Our team of experienced professionals is dedicated to helping our clients achieve their desired aesthetic goals and improving their overall quality of life.",
            3: "Breast Aesthetics",
            text3:
              "Breast aesthetics involves surgical procedures aimed at enhancing the appearance, size, shape, and symmetry of the breasts.\n Common breast aesthetic procedures include breast augmentation, breast lift, breast reduction, and breast reconstruction.\n These procedures can improve the overall aesthetic appearance of the breasts, boost self-confidence, and enhance the quality of life for many women.",
            4: "Medical Aesthetics",
            text4:
              "The use of minimally invasive cosmetic treatments to enhance one's appearance and address common skin and aging concerns.\n These treatments are typically performed by licensed medical professionals, such as dermatologists, plastic surgeons, or trained nurses. Some common medical aesthetic treatments include injectables such as Botox and dermal fillers, skin resurfacing treatments such as chemical peels and laser therapy, and body contouring procedures like CoolSculpting.\n Medical aesthetics can be a great way to achieve natural-looking results without the need for invasive surgeries or lengthy recovery times. It's important to seek out a reputable and experienced provider to ensure safe and effective treatment.",
          },
          dentalPage: {
            header1: "Our Dental Services",
            header2: "Our Services",
            1: "DENTAL IMPLANT",
            text1:
              "Dental implants are a popular and effective way to replace missing teeth.\n They involve placing a small titanium post into the jawbone, which serves as a replacement root for the missing tooth.\n A custom-made dental crown is then attached to the post, providing a natural-looking and durable replacement for the missing tooth.\n Implants can also be used to support dentures or bridges, improving their stability and function.\n The procedure is typically performed under local anesthesia and has a high success rate.",
            2: "HOLLYWOOD SMILE",
            text2:
              "Hollywood smile is a cosmetic dental procedure that involves a combination of several treatments such as teeth whitening, porcelain veneers, and sometimes dental implants to improve the appearance of the teeth and create a beautiful, white, and perfect smile.\n This procedure is inspired by the bright and flawless smiles of Hollywood celebrities and aims to enhance the natural beauty of a person's teeth.\n It is a popular cosmetic treatment that can help to boost confidence and self-esteem.",
          },
          HealthTourismPage: {
            header1: "Why Turkey?",
            text1:
              "Turkey has a unique position connecting the continents of Europe and Asia.\n Millions of people prefer Turkey as tourists every year.\n You can reach Turkey in a very short time by plane from many countries.",
            header2: "Get quality health care at affordable prices…\n",
            text2:
              "Turkey has become a leading country in the medical world with its internationally specialized physicians and modern hospitals equipped with the latest technology. It ranks 2nd in the world with more than 50 hospitals accredited by JCI. \n In addition, from the moment you arrive in the country, you will receive all kinds of services you may need in our modern hospitals with expert physician staff, with the assurance of Romoy.",
            header3: "Preferred Country in Health Tourism Turkey",
            text3:
              "Turkey is a country that connects the continents of Europe and Asia, and has been the cradle of civilizations and cultures for centuries. It is a very rich geography with its mountains, caves, seas, many more natural beauties and historical heritage brought by different civilizations with thousands of years of history.",
            header4: "Cultural and Historical Riches",
            text4:
              "From east to west, north to south, every region and every land has a different treasure and a different richness in every corner, Turkey is almost an open-air museum.\n It has hosted dozens of civilizations such as Sumer, Assyrian, Hittite, Greek, Lydian, Celtic, Persian, Roman, Eastern Roman (Byzantine), Seljuk, Mongolian and Ottoman.",
          },
          contactUsPage: {
            header: "Contact Us",
            SubmitQuestions: "Submit",
            Questions: "Your Questions",
            checkServices: "Check Our Services",
            askQuestions: "Ask Your Questions Here ",
            SubmitBuuton: "Submit",
          },
          aboutUsPage: {
            header1: "Who We Are",
            text1:
              "Welcome to\nDeniz Nedimoglu\nour Health Tourism Corporation in Istanbul! We specialize in a range of beauty treatments, including:",
            1: "Hair Treatment and Transplant",
            2: "Body Shaping And Skin Treatments",
            3: "Plastic Surgeries",
            4: "Dental Aesthetics and Treatments",
            text2:
              "Our team of experienced professionals is dedicated to providing high-quality services and personalized care to each of our clients. We use only the best products and techniques to ensure that you leave our clinic feeling and looking your best.",
            text3: "Visit us today or get your FREE Consultation",
            header2: "Our Partners",
          },
          estesirius: {
            1: "Dr. Dilek Akın Aqrah Clinic",
            2: "Dr.Dilek Akın Aqrah Clinic",
            3: "At our corporate clinic Estesirius, where we offer the latest in hair and skin treatments.\n\nOur clinic is staffed by highly skilled and experienced medical professionals who are committed to providing our patients with the best possible care.\n\nWe understand that hair loss and skin problems can be a source of great distress for many people, which is why we offer a range of treatments designed to address these issues.\n\nOur hair treatment services use the most advanced techniques to ensure that our patients achieve natural-looking, long-lasting results.\n\nIn addition to our hair treatment services, we also offer a variety of skin treatments, including laser skin resurfacing, chemical peels, and microdermabrasion.\n\nThese treatments are designed to address a range of skin concerns, from acne and sun damage to wrinkles and age spots.\n\nAt our clinic, we believe that every patient deserves personalized care, which is why we take the time to get to know each individual and tailor our treatments to their unique needs.\n\nOur goal is to help our patients feel confident and comfortable in their own skin, and we are committed to achieving this through safe, effective, and cutting-edge treatments.",
            4: "The Best Of Our Services",
            5: "Mesotherapy",
            6: "Filler",
            7: "Non-Surgical Face Lift",
            8: "PRP",
            9: "Permanent Makeup",
            10: "Plexer",
            11: "Skin Care",
            12: "Stem Cell Therapy",
            13: "Botox",
            14: "Call EsteSirius Clinic",
            15: "Visit EsteSirius Clinic Website",
            16: "CHECK HAIR TRANSPLANT SERVICES",
            17: "CHECK MEDICAL AESTHETIC SERVICES",
          },
          dentalcorperation: {
            1: "Special Avangart Mouth and Dental Clinic",
            2: "Avangart Clinic",
            3: "Avangart Oral and Dental Health Polyclinic provides services in Mecidiyeköy, Istanbul.\n\nX-ray imaging of the patients is done with the panoramic X-Ray device in Our Clinic.\n\nAvangart Oral and Dental Health Polyclinic is authorized to engage in international health tourism activities by complying with the Ministry of Health of Turkey's International Health Tourism Health Facility competency criteria and the general principles of the Ministry's Regulation.\n\nTreatments are carried out in accordance with the conditions of the pandemic.\n\nHygiene rules are given utmost attention.\n\nAll other dental treatments are performed in the clinic, except orthodontic and pediatric treatments.",
            4: "The Best Of Our Services",
            5: "Hollywood Smile (Smile Aesthetics)",
            6: "Dental Implant",
            7: "Root Canal Treatment",
            8: "Teeth Whitening",
            9: "Dental Prosthesis",
            10: "Tooth Extraction",
            11: "Visit Avangart Clinic Instagram Page",
            12: "Visit Avangart Clinic Website",
            13: "CHECK OUR DENTAL SERVICES",
          },
          plasticClinic: {
            1: "Dr. Selahattin Tulunay",
            2: "Dr.Selahattin Tulunay Clinic",
            3: "In 2001, he was appointed to serve at the Plastic and Reconstructive Surgery Department of Universal Hospital Group (UGH), where he continued to practice his profession.\n\nFrom 2012, he became the head of his own clinic and has been dedicated to serving in the field of plastic surgery for many years.\n\nDr. Selahattin Tulunay is a member of the Turkish Medical Association and the Turkish Plastic Reconstructive and Aesthetic Surgery Association. He was awarded the prize in the contest 'The Best Plastic Surgeon of The Year' held in 2012. He continues his studies and research at his clinic and a partner hospital. He was awarded the prize in the contest 'The Best Hair Transplant Specialist of the Year' held in 2014 and 2015.\n\nDr. Selahattin Tulunay is fluent in English. He has various domestic and international medical publications and endeavors to stay current by attending congresses. He closely follows and participates in congresses and seminars held both domestically and internationally.",
            4: "Specializing in:",
            5: "Aesthetic and Plastic Surgeries",
            6: "Hair Transplant",
            7: "Medical Aesthetic / Non-Surgical Procedures",
            8: "Reconstructive Surgeries",
            9: "Call Dr.Selahattin Clinic",
            10: "Visit Dr.Selahattin Website",
            11: "Check out plastic surgery services",
          },
        },
      },
      tr: {
        translation: {
          navbar: {
            1: "Kurumsal",
            2: "Iletısım ",
            3: "Hakkımızda",
            4: "Hizmetler",
            5: "Türkiye'de Sağlık Turizmi",
            6: "Saç Ekimi",
            7: "Medikal Estetik",
            8: "Diş Estetiği",
            9: "Estetik ameliyatlar",
            10: "Bize Ulaşın",
            11: "Anlaşmalı Kurumlar",
            12: "Dr.Dilek Akın Aqrah Muyanehanesi",
            13: "Özel Avangart Ağız Ve Diş Sağlıgı Polikliniği",
            14: "DR Selahattin Tulunay klink",
            15: "Ulaşım",
            16: "Konaklama",
            17: "İletişim",
            18: "Uçuş Hizmetleri",
            19: "Sigorta Hizmetleri",
            20: "Çeviri",
            21: "Tedaviler",
          },
          pageHeaders: {
            1: "Konaklama Hizmetlerimiz",
            2: "Çeviri Hizmetlerimiz",
            3: "Ulaşım Hizmetlerimiz",
            4: "Uçuş Hizmetlerimiz",
            5: "Sigorta Hizmetlerimiz",
            6: "Farklı konaklama seçeneklerimizle Türkiye'de bir yerli gibi yaşayın.\n Tedaviniz süresince sizin ve yakınlarınızın kalacağı tercihinize göre 4 ve 5 yıldızlı şehir otellerimizde kendinizi evinizde hissedeceksiniz.\n Ajansımız, Türkiye Seyahat Acentaları Birliği (TÜRSAB) üyesidir.\n Şehir merkezinde, alışveriş merkezlerine ve birçok kültürel ve tarihi merkeze kolay erişilebilir otellerle anlaşmalarımız bulunmaktadır. İhtiyaçlarınıza uygun olarak özel indirimli otel seçenekleri sunabiliriz, istediğiniz oda tipi ve konfor için.\n",
            7: "Türkiye'deki tedavi süreciniz boyunca; tedavinizi gerçekleştirecek ekiple yaptığınız toplantılarınızda, transfer ve konaklama süreçlerinizde size atanmış yardımcılarımız tarafından tercüme hizmeti sağlanır.\n",
            8: "Farklı ülkelerden ve şehirlerden gelen hastaları ve yakınlarını havaalanlarında karşılamak; planlanan rotalara ulaşım için özel servis araçları ve taşıtlarla transfer hizmetleri sağlamak.\n Hastalarımızın sağlık durumlarına bağlı olarak, gerektiğinde tam teşekküllü ambulans desteği de sağlanır.\n Size eşlik etmek üzere Şirketin seyahat yetkilileri tarafından yolculuğunuz boyunca olası taleplerinize ve ihtiyaçlarınıza tam olarak yanıt verilir, bu yolculuk havaalanında buluşmanızla başlar ve ülkemize varışınızı takip eder.\n",
            9: "Uluslararası Hava Taşımacılığı Birliği (IATA) üyesi olan organizasyonumuz, tüm iç ve dış havayolu şirketlerinin biletlerini temin eder; bütçenize ve seyahat planınıza en uygun uçuş alternatiflerini sizin için araştırır.\n Uçuşunuz için seçtiğiniz tarih ve rotaya göre tüm havayolu şirketlerinin uçak bilet fiyatlarını karşılaştırır; size en uygun uçuş bilet alternatiflerini sunar.\n Profesyonel seyahat danışmanlığı hizmetlerimizle, çağrı merkezimize ulaşabilir ve tüm havayolu şirketlerinin en ekonomik uçak biletlerini güvenli ve kolay bir şekilde satın alabilirsiniz.\n Özel danışmanlık hizmetimizle, seyahat yetkililerimiz adınıza seçenek süreçlerinizi takip eder; rezervasyon değişiklikleri ve iptal taleplerinizde hızlı destek sağlar.\n",
            10: "Seyahat danışmanlarımız, seyahatinizden önce finansal endişelerinizi ortadan kaldırmak ve insan sağlığının en önemli konu olduğu seyahatinizde sigorta hizmetleri hakkındaki sorularınızı cevaplamak ve gerekli desteği sağlamak için hazırdır.\n Eğer uluslararası sağlık sigortanız varsa veya tedavi masraflarınız bir devlet tarafından karşılanacaksa; seyahatinizden önce şirketimizin yetkililerine bilgi verirseniz, tüm işlemlerin tamamlanması için gerekli planlama tarafımızdan yapılacaktır. Eğer herhangi bir sigorta kapsamanız yoksa; talebiniz üzerine, ülkemize seyahatiniz sırasında tüm hastalarınız ve yakınlarınız güvence altına alınacaktır;\n En kapsamlı ve ekonomik özel sigorta poliçeleri size danışmanlarımız tarafından detaylı bilgiyle sunulacaktır.\n",
          },
          header1: {
            1: "Bıze Ulaşın +905550333444",
          },
          header3: {
            1: "+90 555 033 34 44",
            2: "24/7 Müsait",
            3: "WhatsApp'ta 7/24 Ulaşılabilir",
            4: "kılavuz",
            5: "Ücretsiz danışmanlık",
            6: "KLİNİKLERİMİZ",
          },
          footerHeaders: {
            1: "Hizmetler",
            Accommodations: "Konaklama",
            Translation: "Çeviri",
            Flight: "Uçuş",
            Transportation: "İletişim",
            Insurance: "Sigorta",
          },
          footerDetails: {
            0: "Ana Sayfa",
            1: "Kategoriler",
            2: "Neden Türkiye?",
            3: "Bize Ulaşın",
            4: "Ücretsiz Danışma",
            5: "Hakkımızda",
          },
          HomeCard: {
            title1: "Medikal Estetik",
            titel2: "Saç Tedavileri",
            titel3: "Estetik ameliyatlar",
            title4: "Hayatları\n**Transform**\nEdiyoruz",
            title5: "Yeni Bir Senin \n **Güveni**",
            title6: "Bilimin \n **Sanatla**\n Buluştuğu Yer",
            title7: "Güzelliğiniz Emin Ellerde Olacak",
            title8: "Deniz Nedimoğlu Sağlık Turizmi",
          },
          HomePercentage: {
            1: "Ücretsiz çevrimiçi Danışmanlıklar",
            2: "Akredite Hastaneler ve Klinikler",
            3: "Tedavi Edilen Hastalar",
            4: "Olumlu geribildirim",
          },
          homeCategories: {
            header: "Her Konuda Yardımcı Oluyoruz",
            1: "Hızlı Yanıt",
            2: "Sorgularınıza hızlı yanıtlar alın, genellikle dakikalar içinde.",
            3: "Ücretsiz Danışma",
            4: "Vakaya özel uzmanlaşmış bir Profesör Doktor ile doğrudan konuşun.",
            5: "7/24 Destek",
            6: "Size ihtiyacınız olduğunda her zaman destek için çevrimiçiyiz.",
            7: "Konaklama",
            8: "Sizi havalimanında karşılayıp varış noktanıza kadar eşlik ediyoruz.",
            9: "Çeviri",
            10: "Sizin dilinizi konuşuyoruz ve gerektiğinde çeviri hizmeti sunuyoruz.",
            11: "Memnun Hastalar",
            12: "Hastalarımızın %99'dan fazlası deneyimlerinden memnun olduklarını bildiriyor.",
            13: "En İyi Uzman Doktorlar",
            14: "Yüksek eğitimli ve uluslararası üne sahip doktorlarımız ve profesörlerimiz size en iyi bakımı sağlıyor.",
            15: "Online Randevu",
            16: "Konforlu evinizden kolayca randevunuzu planlayın.",
            17: "En İyi Seçenekler",
            18: "Kendi ülkenizde ve dünya genelinde en iyi tedavi seçenekleri hakkında bilgi edinin.",
            19: "En İyi Hastaneler",
            20: "Türkiye genelinde 300 akredite tesis arasından tıbbi ihtiyaçlarınız için seçim yapın.",
          },
          skinPage: {
            Header1: "Cildinize Nasıl Bakıyoruz?",
            Header2: "Süreç:",
            Header3: "Adımlar:",
            Text1:
              "Tıbbi cilt bakımı, cildin canlılığını geri kazanmayı ve yağ, siyah noktalar ve makyaj kalıntıları gibi mat görünmesine neden olan faktörlerden arındırmayı amaçlayan bir süreçtir.\n Süreç, cilt analiziyle ihtiyaçlarının belirlenmesi, cildin temizlenmesi, peeling ve dezenfeksiyonu içerir.\n Ardından cilt nemlendirilir ve gerektiğinde ek tedaviler uygulanabilir.",
            Text2: {
              step1:
                "Cilt bakım süreci, cilt analiziyle ihtiyaçlarının belirlenmesiyle başlar.\n",
              step2: "Ardından temizlenir\n",
              step3: "Cilt, özel ürünler kullanılarak eksfoliye edilir\n",
              step4: "Ardından buhar terapisi ile gözenekler açılır\n",
              step5:
                "Derin bir dezenfeksiyon yapılır, kalan yağ ve makyaj kalıntıları temizlenir\n",
              step6: "Süreç, nemlendirme ve bakım maskesi ile sona erer\n",
            },
            Services: {
              Services: "Medikal Estetik Hizmetlerinden",
              1: "SİVİLCE VE YARA İZİ TEDAVİLERİ",
              2: "PROFESYONEL CİLT BAKIMI",
              3: "JEL YÜZ MASKESİ",
              4: "KARBON PEELING",
              5: "CİLT LEKELERİ TEDAVİSİ",
              6: "DERİN CİLT TEMİZLİĞİ",
              7: "NEVÜS TEDAVİSİ",
              8: "LAZER DÖVME SİLME",
              9: "DERMA TERAPİSİ",
              10: "CİLT PEELİNGİ",
              11: "CİLT ANALİZİ",
              12: "FRAXEL LAZER GERME İZLERİ TEDAVİSİ",
            },
          },
          hairPage: {
            header1: "Saç Ekimi Tedavileri",
            header2: "Hizmetlerimiz",
            1: "Kadınlar İçin Saç Ekimi",
            text1:
              "Tıraşsız saç ekimi, bu amaçla geliştirilen bir yöntemdir.\n Saç ekimi özellikle ön bölge olarak adlandırılan alanda yapılabilir.\n Özellikle kadınlar, saç ekimi için saçların tıraş edilmesi gerekliliği veya hastaların bu işlemin fark edilmesini istememesi nedeniyle vazgeçebilirler. Tıraşsız Saç Ekimi, mevcut uzun saçlarınızın arasında yapılabilir ve sosyal hayatı etkilemeyen bir tedavi sunar.",
            2: "Erkekler İçin Saç Ekimi",
            text2:
              "Bu teknik, saç olmayan bölgelere, insanın ense bölgesinden tek tek alınan saç köklerinin transfer edildiği bir saç ekimi yöntemidir.\n Herhangi bir bölgeden (arka, göğüs, bacak vb.) alınan saçlar da transfer edilebilir.\n",
            3: "Sakal Ekimi",
            text3:
              "Sakal ekimi, yüzdeki akne izleri veya farklı türdeki hasarların bu izleri kapatmak için yapılabilir.\n Söz konusu izler, çene, yanaklar, bıyık veya favori bölgesinde gelişebilir.\n Bu yöntemle izlerin görünürlüğü ortadan kaldırılabilir.\n",
          },
          PlasticSurguries: {
            header1: "ESTETİK CERRAHİLER",
            header2: "Hizmetlerimiz",
            1: "Yüz Estetiği",
            text1:
              "Botox: Botox, geçici olarak onları oluşturan kasları rahatlatarak kırışıklıkları ve ince çizgileri düzleştirmek için kullanılan bir nöromodülatördür. Sıklıkla kırışık çizgileri, kaz ayağı ve alın çizgilerinin tedavisinde kullanılır.\n Dermal dolgular: Dermal dolgular, yüzdeki hacmi ve dolgunluğu geri kazandırmak için kullanılan enjekte edilebilir jellerdir. Dudakları, yanakları ve göz altı boşluklarını dolgunlaştırmak, ayrıca kırışıklıkları ve ince çizgileri düzleştirmek için kullanılabilir. \n Mikrodermabrazyon: Mikrodermabrazyon, cildin üst tabakasını soyarak yapılan invaziv olmayan bir tedavidir. İnce çizgilerin ve kırışıklıkların görünümünü azaltmaya, cilt dokusu ve tonunu düzeltmeye ve akne izleri ile güneş hasarının görünümünü azaltmaya yardımcı olabilir.\n Trombosit zengin plazma (TVP) terapisi: TVP terapisi, hastanın kendi kanının kullanıldığı, trombositleri ve büyüme faktörlerini izole etmek için işlenen bir yöntemdir. Bunlar daha sonra hastanın yüzüne enjekte edilerek kollajen üretimini uyarır ve cilt kalitesini iyileştirir.\n",
            2: "Vücut Estetiği",
            text2:
              "Vücudun görünümünü iyileştirmeye odaklanan çeşitli kozmetik işlemler.\n Klinikte, liposuction, karın germe ve vücut şekillendirme gibi vücut estetiği hizmetleri sunmaktayız. Liposuction, karın, kalça ve uyluk gibi belirli bölgelerden fazla yağın alındığı bir işlemdir. Öte yandan karın germe, karın bölgesinden fazla deri ve yağın alınarak daha düz ve daha sıkı bir görünüm elde edilmesini sağlar.\n Vücut şekillendirme işlemleri, vücudu yeniden şekillendirmek için tasarlanmıştır ve kontrol edilen soğutma kullanarak yağ hücrelerini yok eden CoolSculpting gibi tedavileri ve lazer teknolojisini kullanarak yağ hücrelerini hedefleyen SculpSure gibi tedavileri içerir. Tecrübeli bir uzman ekibimiz, müşterilerimizin istedikleri estetik hedeflere ulaşmalarına ve genel yaşam kalitelerini iyileştirmelerine yardımcı olmaktadır.\n",
            3: "Göğüs Estetiği",
            text3:
              "Göğüs estetiği, göğüslerin görünümünü, boyutunu, şeklini ve simetrisini iyileştirmeyi amaçlayan cerrahi işlemleri içerir.\n Yaygın göğüs estetiği işlemleri arasında meme büyütme, meme dikleştirme, meme küçültme ve meme rekonstrüksiyonu bulunur.\n Bu işlemler, göğüslerin genel estetik görünümünü, özgüveni artırabilir ve birçok kadının yaşam kalitesini iyileştirebilir.\n",
            4: "Tıbbi Estetik",
            text4:
              "Görünümü iyileştirmek ve yaygın cilt ve yaşlanma sorunlarını ele almak için minimal invaziv kozmetik tedavilerin kullanılmasıdır.\n Bu tedaviler genellikle dermatologlar, plastik cerrahlar veya eğitimli hemşireler gibi lisanslı tıbbi profesyoneller tarafından uygulanır. Bazı yaygın tıbbi estetik tedaviler arasında Botox ve dermal dolgular gibi enjektabl ürünler, kimyasal peeling ve lazer terapisi gibi cilt yenileme tedavileri, ve CoolSculpting gibi vücut şekillendirme prosedürleri yer almaktadır.\n Tıbbi estetik, invaziv cerrahiler veya uzun iyileşme süreleri gerektirmeyen doğal görünümlü sonuçlara ulaşmanın harika bir yol olabilir. Güvenli ve etkili bir tedavi için saygın ve deneyimli bir sağlayıcıyı tercih etmek önemlidir.",
          },
          dentalPage: {
            header1: "Diş Hekimliği Hizmetlerimiz",
            header2: "Hizmetlerimiz",
            1: "DİŞ İMPLANTI",
            text1:
              "Diş implantları, eksik dişleri yerine koymak için popüler ve etkili bir yöntemdir.\n Bu yöntemde, çene kemiğine yerleştirilen küçük bir titanyum direk, eksik dişin yerine geçen bir kök görevi görür.\n Ardından özel olarak yapılmış bir diş taçı, direğe bağlanarak eksik dişin doğal ve dayanıklı bir yerine geçmesini sağlar.\n İmplantlar, protez veya köprüleri desteklemek için de kullanılabilir, böylece stabilite ve fonksiyonları artar.\n İşlem genellikle lokal anestezi altında gerçekleştirilir ve yüksek bir başarı oranına sahiptir.\n",
            2: "HOLLYWOOD GÜLÜŞÜ",
            text2:
              "Hollywood gülüşü, diş beyazlatma, porselen lamineler ve bazen diş implantları gibi birkaç tedaviyi birleştiren kozmetik diş tedavisidir. Bu yöntem, Hollywood ünlülerinin parlak ve kusursuz gülüşlerinden esinlenmiştir ve kişinin dişlerinin doğal güzelliğini artırmayı amaçlar.\n Özgüveni ve özsaygıyı artırmaya yardımcı olan popüler bir kozmetik tedavidir.\n",
          },
          HealthTourismPage: {
            header1: "Neden Türkiye?",
            text1:
              "Türkiye, Avrupa ve Asya kıtalarını birleştiren benzersiz bir konuma sahiptir.\n Her yıl milyonlarca insan Türkiye'yi turist olarak tercih etmektedir.\n Birçok ülkeden uçakla Türkiye'ye çok kısa sürede ulaşabilirsiniz.\n",
            header2: "Uygun fiyatlarla kaliteli sağlık hizmeti alın…\n",
            text2:
              "Türkiye, uluslararası alanda uzmanlaşmış doktorları ve son teknolojiyle donatılmış modern hastaneleri ile tıp dünyasında öncü bir ülke haline gelmiştir. JCI tarafından akredite edilen 50'den fazla hastane ile dünyada 2. sırada yer almaktadır.\n Ayrıca ülkeye geldiğiniz anda, Romoy güvencesiyle uzman doktor kadrosuyla modern hastanelerimizde ihtiyaç duyabileceğiniz her türlü hizmeti alırsınız.\n ",
            header3: "Sağlık Turizminde Tercih Edilen Ülke Türkiye",
            text3:
              "Türkiye, Avrupa ve Asya kıtalarını birleştiren, yüzyıllardır medeniyetlerin ve kültürlerin beşiği olan bir ülkedir. Dağları, mağaraları, denizleri ve binlerce yıllık tarihiyle farklı medeniyetler tarafından getirilen doğal güzellikler ve tarihi mirasıyla çok zengin bir coğrafyadır.\n",
            header4: "Kültürel ve Tarihi Zenginlikler",
            text4:
              "Doğudan batıya, kuzeyden güneye her bölge ve her toprak parçasında farklı bir hazine ve her köşede farklı bir zenginlik bulunan Türkiye neredeyse açık hava müzesidir.\n Sümer, Asur, Hitit, Yunan, Lidya, Kelt, Pers, Roma, Doğu Roma (Bizans), Selçuklu, Moğol ve Osmanlı gibi onlarca medeniyete ev sahipliği yapmıştır.\n",
          },
          contactUsPage: {
            header: "Bize Ulaşın",
            SubmitQuestions: "Soruları",
            Questions: "Gönderin",
            checkServices: "Hizmetlerimizi Kontrol Edin",
            askQuestions: "Sorularınızı Buradan Sorun ",
          },
          aboutUsPage: {
            header1: "Kimiz",
            text1:
              "İstanbul'daki Sağlık Turizmi Kuruluşumuz \nDeniz Nedimoğlu'na\n hoş geldiniz! Saç tedavisi, saç ekimi, vücut şekillendirme ve cilt tedavileri gibi çeşitli güzellik tedavilerinde uzmanlaşmış bir kuruluşuz.",
            1: "Saç Tedavisi ve Saç Ekimi",
            2: "Vücut Şekillendirme ve Cilt Tedavileri",
            3: "Plastik Cerrahiler",
            4: "Diş Estetiği ve Tedavileri",
            text2:
              "Deneyimli uzman ekibimiz, her bir müşterimize yüksek kalitede hizmet ve kişiselleştirilmiş bakım sunmaya adanmıştır.\n Sadece en iyi ürünleri ve teknikleri kullanarak, kliniğimizden en iyi şekilde ayrılmanızı sağlıyoruz.",
            text3:
              "Bugün bizi ziyaret edin veya ÜCRETSİZ danışmanlığınızı alın",
            header2: "İş Ortağımız",
            11: "Dr.Dilek Akın Aqrah Klinik",
            text11:
              "Son saç ekimi ve cilt tedavilerinde en son teknikleri sunduğumuz kurumsal kliniğimiz Estesirius'ta.\n\n Kliniğimiz, hastalarımıza en iyi bakımı sunmaya adanmış deneyimli ve alanında uzman tıbbi profesyoneller tarafından çalıştırılmaktadır.\n\n Saç kaybı ve cilt sorunlarının birçok insan için büyük bir sıkıntı kaynağı olabileceğinin farkındayız, bu yüzden bu sorunları ele alan çeşitli tedaviler sunuyoruz.\n\n Saç ekimi hizmetlerimiz, Foliküler Ünite Ekstraksiyonu (FUE) ve Foliküler Ünite Transplantasyonu (FUT) gibi en ileri teknikleri kullanarak hastalarımızın doğal görünümlü ve uzun süreli sonuçlar elde etmesini sağlıyoruz.\n\n Saç ekimi hizmetlerimizin yanı sıra, lazer cilt yenileme, kimyasal peeling ve mikrodermabrazyon gibi çeşitli cilt tedavileri de sunuyoruz.\n\n Bu tedaviler, akne ve güneş hasarı gibi çeşitli cilt sorunlarını ele almak için tasarlanmıştır.\n\n Kliniğimizde, her hastanın kişiselleştirilmiş bakıma layık olduğuna inanıyoruz, bu nedenle her bir bireyi tanımak ve tedavilerimizi benzersiz ihtiyaçlarına uygun şekilde uyarlamak için zaman ayırıyoruz.\n\n Amacımız, hastalarımızın kendi ciltlerinde kendine güvenen ve rahat hissetmelerine yardımcı olmaktır ve bunu güvenli, etkili ve son teknoloji tedavilerle başarmaya kararlıyız.\n\n",
          },
          estesirius: {
            1: "Dr.Dilek Akın Aqrah ",
            2: "Dr.Dilek Akın Aqrah Muayenehanesi",
            3: "Kurumsal klinik Estesirius'ta, en son saç ve cilt tedavilerini sunduğumuz yerdeyiz.Kliniğimiz, hastalarımıza en iyi bakımı sunmaya adanmış,\n  son derece yetenekli ve tecrübeli tıbbi profesyoneller tarafından çalıştırılmaktadır.\n Saç dökülmesi ve cilt sorunlarının birçok insan için büyük bir sıkıntı kaynağı olabileceğini anlıyoruz, bu nedenle bu sorunları ele almaya yönelik çözümler sunan bir dizi tedavi seçeneği sunuyoruz.\n \n Saç tedavi hizmetlerimiz, hastalarımızın doğal görünümlü ve uzun süreli sonuçlar elde etmelerini sağlamak için en gelişmiş teknikleri kullanır.Saç tedavisi hizmetlerimize ek olarak, lazer cilt yenileme, kimyasal peeling ve mikrodermabrazyon gibi birçok cilt tedavisi seçeneği de sunuyoruz.Bu tedaviler, akne ve güneş hasarı gibi birçok cilt sorununu ele almaya yöneliktir.\n\n Kliniğimizde, her hasta kişiselleştirilmiş bakımı hak eder.\n  Bu nedenle, her bireyi tanımak ve tedavilerimizi onların özel ihtiyaçlarına göre uyarlamak için zaman ayırıyoruz.\n Amacımız, hastalarımızın kendi ciltlerinde kendine güvenli ve rahat hissetmelerine yardımcı olmaktır ve bunu güvenli, etkili ve son derece ileri teknolojili tedavilerle başarmak için taahhütte bulunuyoruz.",
            4: "Hizmetlerimizin En İyisi",
            5: "Mezoterapi",
            6: "Dolgu",
            7: "Cerrahi Olmayan Yüz Germe",
            8: "PRP",
            9: "Kalıcı Makyaj",
            10: "Plexer",
            11: "Cilt Bakımı",
            12: "Kök hücre tedavisi",
            13: "Botoks",
            14: "Clinic'i arayın",
            15: "Web Sitesine Geçin",
            16: "SAÇ EKİMİ Hizmetlerimize Geçin",
            17: "Hizmetlerimizi Kontrol Edin",
          },
          dentalcorperation: {
            1: "Special Avangart Mouth and Denatl",
            2: "Avangart Muayenehanesi",
            3: "Avangart Ağız ve Diş Sağlığı Polikliniği, İstanbul Mecidiyeköy'de hizmet vermektedir.\n Hastaların röntgen görüntülemesi Kliniğimizde panoramik X-ışını cihazı ile yapılmaktadır.\n Avangart Ağız ve Diş Sağlığı Polikliniği, Türkiye Sağlık Bakanlığı'nın Uluslararası Sağlık Turizmi Sağlık Tesisi yetkinlik kriterlerine ve Bakanlık yönetmeliğinin genel prensiplerine uygun olarak uluslararası sağlık turizmi faaliyetlerinde bulunmaya yetkilidir.\n Tedaviler pandemi koşullarına uygun şekilde gerçekleştirilmektedir.\n Hijyen kurallarına maksimum önem verilmektedir.\n Ortodontik ve pedodontik tedaviler haricindeki tüm diş tedavileri klinikte yapılmaktadır.\n",
            4: "Hizmetlerimizin En İyileri",
            5: "Hollywood Gülüşü (Gülüş Estetiği)",
            6: "Diş İmplantı",
            7: "Kanal Tedavisi",
            8: "Diş Beyazlatma",
            9: "Diş Protezi",
            10: "Diş Çekimi",
            11: "Avangart,Instagram Sayfasına Geçin",
            12: "Avangart Kliniği Websitesine Geçin",
            13: "Diş Hizmetlerimizi Kontrol Edin",
          },
          plasticClinic: {
            1: "Dr. Selahattin Tulunay",
            2: "Dr.Selahattin Tulunay Muayenehanesi",
            3: "2001 yılında Universal Hospital Group'un (UGH) Plastik ve Rekonstrüktif Cerrahi Bölümü'ne atanarak mesleğini burada sürdürmüştür.\n2012 yılından itibaren ise kendisini hizmet vermeye adadığı kendi kliniğinin başına geçmiştir. Selahattin Tulunay Türk Tabipleri Birliği üyesidir.\n Türk Plastik Rekonstrüktif ve Estetik Cerrahi Derneği üyesidir. 2012 yılında düzenlenen “Yılın En İyi Plastik Cerrahı” yarışmasında ödüle layık görülmüştür. Çalışmalarına ve araştırmalarına kendi kliniğinde ve anlaşmalı olduğu hastanede devam etmektedir. 2014 ve 2015 yıllarında düzenlenen “Yılın En İyi Saç Ekim Uzmanı” yarışmasında 2 kez ödüle layık görülmüştür.\n Dr. Selahattin Tulunay iyi derecede İngilizce bilmektedir. Yurt içi ve yurt dışında çeşitli tıp yayınları bulunan Dr. Tulunay, kongrelere katılarak güncel kalmaya çalışmaktadır. Ayrıca yurt içi ve yurt dışında düzenlenen kongre ve seminerleri yakından takip etmekte ve bunlara katılmaktadır.\n",
            4: "Uzmanlık:",
            5: "Estetik ve Plastik Ameliyatlar",
            6: "Saç Ekimi",
            7: "Medikal Estetik / Ameliyatsız İşlemler",
            8: "Rekonstrüktif Ameliyatlar",
            9: "Dr.Selahattin Kliniği'ni Arayın",
            10: "Dr.Selahattin Web Sitesine Geçin",
            11: "Estetik hizmetlerimizi Kontrol Edin",
          },
        },
      },
      ar: {
        translation: {
          navbar: {
            1: "المؤسسة",
            2: "التواصل ",
            3: "من نحن",
            4: "خدماتنا",
            5: "السياحة الطبية في تركيا",
            6: "زراعة الشعر",
            7: "التجميل الطبي",
            8: "تجميل الأسنان",
            9: "عمليات التجميل",
            10: "اتصل بنا",
            11: "المؤسسات المتعاقد معها",
            12: "Estesirius عيادة",
            13: "الخاصة بصحة الفم و الاسنان Avangart عيادة",
            14: "عيادة الدكتور صلاح الدين تولاني التجميلية",
            15: "النقل",
            16: "الإقامة",
            17: "الاتصال",
            18: "خدمات الطيران",
            19: "خدمات التأمين",
            20: "الترجمة",
            21: "العلاجات",
          },
          header1: {
            1: "أتصل بنا +905550333444",
          },
          pageHeaders: {
            1: "خدمات الإقامة لدينا",
            2: "خدمات الترجمة لدينا",
            3: "خدمات النقل لدينا",
            4: "خدمات الطيران لدينا",
            5: "خدمات التأمين لدينا",
            6: "عيش كأنك ساكن أصلي في تركيا مع خيارات الإقامة المختلفة لدينا.\n ستشعر وكأنك في منزلك مع فنادقنا ذات الـ 4 و 5 نجوم في المدينة، وفقًا لتفضيلك، حيث ستقيم أنت وأقاربك أثناء علاجك.\n وكالتنا، هي عضو في جمعية وكالات السفر التركية (تورساب).\n لدينا اتفاقيات مع فنادق في وسط المدينة يسهل الوصول إليها، قريبة من المجمعات التجارية ومراكز الثقافة والتاريخ. يمكننا تقديم خيارات فندقية خاصة مخفضة لاحتياجاتك في نوع الغرفة والراحة التي ترغب فيها.\n",
            7: "خلال عملية العلاج الخاصة بك في تركيا؛ يتم توفير خدمات الترجمة من قبل مساعدينا المعينين لك خلال اجتماعاتك مع الفريق المكلف بإجراء علاجك، وخلال عملية النقل والإقامة الخاصة بك.\n",
            8: "نرحب بالمرضى وأقاربهم من مختلف البلدان والمدن في المطارات؛ ونقدم خدمات نقل بواسطة حافلات ومركبات خاصة للتنقل إلى الوجهات المخطط لها.\n ونظرًا لحالة المرضى الصحية، يتم أيضًا توفير دعم الإسعافات الكاملة في خدمات النقل عند الحاجة.\n يتم مرافقتك من قبل مسؤولي السفر للشركة للرد بشكل كامل على طلباتك واحتياجاتك المحتملة خلال رحلتك، التي ستبدأ بالاجتماع بك في المطار بعد وصولك إلى بلدنا.\n",
            9: "منظمتنا، التي هي عضو في النقل الجوي الدولي (IATA)، تقوم بتذكير جميع شركات الطيران المحلية والأجنبية؛ وتبحث عن أفضل بدائل الرحلات المناسبة لميزانيتك وخطة سفرك.\n تقارن أسعار تذاكر الطيران لجميع شركات الطيران وفقًا للتاريخ والمسار الذي تختاره لرحلتك؛ وتقدم لك بدائل تذاكر الطيران الأكثر اقتصادية.\n مع خدماتنا المهنية للاستشارات السفرية، يمكنك الوصول إلى مركز الاتصال لدينا وشراء أكثر تذاكر الطيران اقتصادية لجميع شركات الطيران بأمان وسهولة.\n بفضل خدمتنا الاستشارية الخاصة، يتابع مسؤولو السفر خياراتك بالنسبة للمدة المحددة نيابة عنك؛ ويقدمون لك الدعم السريع في طلبات تغيير الحجز والإلغاء.\n",
            10: "مستشارو السفر لدينا جاهزون للإجابة على أسئلتك حول خدمات التأمين وتقديم الدعم اللازم للقضاء على القلق المالي قبل سفرك، حيث يعتبر الصحة البشرية الأمر الأهم.\n إذا كان لديك تأمين صحي دولي أو إذا ستتم تغطية نفقات علاجك من قبل الدولة؛ إذا قمت بإبلاغ السلطات في شركتنا قبل رحلتك، سيتم التخطيط اللازم من قبلنا لتنفيذ جميع المعاملات بشكل كامل. إذا لم يكن لديك أي تغطية تأمينية؛ عند طلبك، سيتم ضمان جميع المرضى وأقاربهم خلال رحلتك إلى بلدنا؛\n سيتم تقديم أشمل وأكثر بوليصات التأمين الخاصة بأسعار معقولة لك من قبل مستشارينا مع معلومات مفصلة.\n",
          },
          header3: {
            1: "+90 555 033 34 44",
            2: "متاح 24/7",
            3: "24/7 متاح على WhatsApp",
            4: "دليل",
            5: "استشارة مجانا",
            6: "عياداتنا",
          },
          footerHeaders: {
            1: "خدماتنا",
            Accommodations: "الإقامة",
            Translation: "الترجمة",
            Flight: "الطيران",
            Transportation: "النقل",
            Insurance: "التأمين",
          },
          footerDetails: {
            0: "الصفحة الرئيسية",
            1: "التصنيفات",
            2: "لماذا تركيا؟",
            3: "اتصل بنا",
            4: "استشارة مجانية",
            5: "معلومات عنا",
          },
          HomeCard: {
            title1: "التجميل الطبي",
            titel2: "علاجات الشعر",
            titel3: "جراحة تجميلية",
            title4: "نحن \n **نحول** \n الحياة",
            title5: "ثقة \n **جديدة** \n بداخلك",
            title6: "حيث يلتقي العلم بـ \n **الفن**",
            title7: "جمالكم سيكون في ايدي امينة",
            title8: "Deniz Nedimoğlu للسياحة الطبية",
          },
          HomePercentage: {
            1: "استشارات مجانية عبر الإنترنت",
            2: "المستشفيات والعيادات المعتمدة",
            3: "المرضى المعالجون",
            4: "ردود الفعل الإيجابية",
          },
          homeCategories: {
            header: "نحن نساعد في جميع الجوانب",
            1: "استجابة سريعة",
            2: "احصل على ردود سريعة على استفساراتك ، في كثير من الأحيان في غضون دقائق.",
            3: "استشارة مجانية",
            4: "تحدث مباشرةً مع البروفيسور الطبيب المتخصص في حالتك.",
            5: "الدعم على مدار الساعة طوال الأسبوع",
            6: "نحن متاحون على مدار الساعة لدعمك في أي وقت تحتاج إلينا.",
            7: "الإقامة",
            8: "سنرحب بك في المطار ونرافقك إلى وجهتك.",
            9: "الترجمة",
            10: "نتحدث لغتك ونوفر خدمات الترجمة حسب الحاجة.",
            11: "المرضى المرتاحون",
            12: "أكثر من 99٪ من مرضانا يبلغون عن رضاهم الشديد بتجربتهم.",
            13: "أفضل الأطباء المتخصصين",
            14: "أطباؤنا وأساتذتنا المتعلمين بشدة والمشهورين عالمياً يضمنون الرعاية الأفضل الممكنة لك.",
            15: "موعد عبر الإنترنت",
            16: "جدولة موعدك بسهولة من راحة منزلك الخاص.",
            17: "أفضل الخيارات",
            18: "تعرف على أفضل خيارات العلاج لحالتك ، سواء في بلدك الخاص أو في جميع أنحاء العالم.",
            19: "أفضل المستشفيات",
            20: "اختر من بين 300 مرفقًا معتمدًا في جميع أنحاء تركيا لاحتياجاتك الطبية.",
          },
          skinPage: {
            Header1: "كيف نهتم ببشرتك؟",
            Header2: "العملية:",
            Header3: "الخطوات:",
            Text1:
              "العناية الطبية بالبشرة هي عملية تهدف إلى استعادة حيوية البشرة باستخدام منتجات العناية بالبشرة وتنقية البشرة من العوامل التي تجعلها تبدو باهتة مثل الزيت والبقع السوداء وبقايا المكياج.\n تشمل العملية تحليل البشرة لتحديد احتياجاتها وتنظيف البشرة وتقشيرها وتطهيرها.\n يتم ترطيب البشرة بعد ذلك ويمكن تطبيق علاجات إضافية حسب الحاجة.",
            Text2: {
              step1:
                "تبدأ عملية العناية بالبشرة بتحليل البشرة لتحديد احتياجاتها.\n",
              step2: "ثم تنظيفها\n",
              step3: "يتم تقشير البشرة باستخدام منتجات خاصة\n",
              step4: "ثم يتم فتح المسام باستخدام العلاج بالبخار\n",
              step5:
                "يتم إجراء التطهير العميق لإزالة الشوائب ويتم اتخاذ الاحتياطات لإزالة أي زيت متبقي أو بقايا مكياج\n",
              step6: "تنتهي العملية بترطيب البشرة وقناع العناية\n",
            },
            Services: {
              Services: "بعض الخدمات الطبية الجمالية",
              1: "علاج حب الشباب والندوب",
              2: "العناية المهنية بالبشرة",
              3: "قناع الوجه بالجل",
              4: "التقشير بالكربون",
              5: "علاج فتحات التصبغ",
              6: "تنظيف البشرة العميق",
              7: "علاج الشامات",
              8: "إزالة وشم بالليزر",
              9: "علاج الدرما",
              10: "تقشير البشرة",
              11: "تحليل البشرة",
              12: "علاج تمدد الجلد بالليزر التجزيئي",
            },
          },
          hairPage: {
            header1: "علاجات زراعة الشعر",
            header2: "خدماتنا",
            1: "زراعة الشعر للنساء",
            text1:
              "زراعة الشعر بدون حلاقة هي طريقة تم تطويرها لهذا الغرض.\n يمكن إجراء زراعة الشعر بشكل خاص على الجهة الأمامية من الرأس المعروفة باسم المنطقة المستقبلة.\n وعلى وجه الخصوص، قد تكون النساء قد تخلوا عن زراعة الشعر بسبب ضرورة حلاقة الشعر أو لا يرغب معظم المرضى في أن يتم اكتشاف هذا الإجراء. يمكن إجراء زراعة الشعر بدون حلاقة بين الشعر الطويل الحالي لديك وتوفر لك علاجًا لا يؤثر على الحياة الاجتماعية.",
            2: "زراعة الشعر للرجال",
            text2:
              "هذه التقنية هي طريقة زراعة الشعر التي تعتمد على نقل جذور الشعر التي تم أخذها من منطقة الرقبة واحدة تلو الأخرى إلى المناطق التي ليس بها شعر.\n يمكن أيضًا نقل الشعر الذي تم أخذه من أي منطقة (الظهر، الصدر، الساقين إلخ).\n",
            3: "زراعة اللحية",
            text3:
              "يمكن أن يتم إجراء زراعة اللحية عند وجود ندوب من حب الشباب أو أنواع مختلفة من الضرر على الوجه لتغطية هذه الندوب.\n يمكن أن تظهر الندوب المذكورة في الذقن أو الخدين أو الشارب أو منطقة الشارب الجانبية.\n يمكن إزالة ظهور الندوب باستخدام هذه الطريقة.\n",
          },
          PlasticSurguries: {
            header1: "عمليات التجميل",
            header2: "خدماتنا",
            1: "جمال الوجه",
            text1:
              "البوتوكس: البوتوكس هو معالج متحكم في الجهاز العصبي يمكن استخدامه لتنعيم التجاعيد والخطوط الدقيقة عن طريق استرخاء العضلات المسؤولة عنها مؤقتًا. يستخدم عادة لعلاج خطوط التجعيد العابسة وتجاعيد حول العينين والجبين.\n مواد التعبئة: مواد التعبئة هي هلامات قابلة للحقن تستخدم لاستعادة الحجم والامتلاء في الوجه. يمكن استخدامها لتوريم الشفاه والخدود والهالات تحت العينين، بالإضافة إلى تنعيم التجاعيد والخطوط الدقيقة.\n تقشير البشرة الميكروسكوبي: تقشير البشرة الميكروسكوبي هو علاج غير غازٍ يستخدم أداة خاصة لتقشير الطبقة العلوية من الجلد. يمكن أن يساعد في تقليل مظهر الخطوط الدقيقة والتجاعيد، وتحسين نسيج البشرة ولونها، وتقليل مظهر ندوب حب الشباب والضرر الناتج عن أشعة الشمس.\n العلاج بمصل البلازما الغنية بالصفائح: ينطوي العلاج بمصل البلازما الغنية بالصفائح على استخدام دم المريض نفسه، حيث يتم معالجته لفصل الصفائح والعوامل النمو. يتم حقن هذه المكونات مرة أخرى في وجه المريض لتحفيز إنتاج الكولاجين وتحسين جودة البشرة.\n",
            2: "جمال الجسم",
            text2:
              "مجموعة متنوعة من الإجراءات التجميلية التي تركز على تحسين مظهر الجسم.\n في عيادتنا، نقدم خدمات جمال الجسم مثل شفط الدهون، تجميل البطن، وتشكيل الجسم. شفط الدهون هو إجراء يزيل الدهون الزائدة من مناطق محددة في الجسم مثل البطن والوركين والفخذين. من ناحية أخرى، يزيل تجميل البطن الجلد الزائد والدهون من منطقة البطن، مما يؤدي إلى مظهر أكثر مرونة ومشدودة.\n إجراءات تشكيل الجسم مصممة لإعادة تشكيل الجسم وتشمل علاجات مثل كولسكلبتينغ، الذي يستخدم التبريد المضبوط للقضاء على خلايا الدهون، وسكالبسور، الذي يستخدم تقنية الليزر لاستهداف وتدمير خلايا الدهون. فريقنا من المحترفين ذوي الخبرة ملتزم بمساعدة عملائنا في تحقيق أهدافهم الجمالية المرغوبة وتحسين جودة حياتهم بشكل عام.\n",
            3: "جمال الثدي",
            text3:
              "جمال الثدي ينطوي على إجراءات جراحية تهدف إلى تحسين مظهر الثدي وحجمه وشكله وتناظره.\n من العمليات الجمالية الشائعة للثدي تشمل زراعة الثدي ورفع الثدي وتصغير الثدي وإعادة البناء. يمكن أن تحسن هذه الإجراءات المظهر الجمالي العام للثديين، وتعزز الثقة بالنفس، وتعزز جودة الحياة للكثير من النساء.\n",
            4: "جمال الطب الجلدي",
            text4:
              "استخدام علاجات التجميل الغير غازية لتحسين المظهر الشخصي ومعالجة مشاكل الجلد الشائعة وعلامات التقدم في العمر.\n يتم تنفيذ هذه العلاجات عادة بواسطة متخصصين طبيين",
          },
          dentalPage: {
            header1: "خدماتنا السنية",
            header2: "خدماتنا",
            1: "زرع الأسنان",
            text1:
              "زرع الأسنان هي طريقة شائعة وفعالة لاستبدال الأسنان المفقودة.\n تتضمن إدخال مسمار تيتانيوم صغير في الفك، والذي يعمل كجذر بديل للسن المفقودة.\n ثم يتم تثبيت تاج أسنان مصمم حسب الطلب على المسمار، مما يوفر استبدالًا طبيعيًا ومتينًا للسن المفقودة.\n يمكن أيضًا استخدام الزرعات لدعم الأطقم السنية أو الجسور، مما يحسن استقرارها ووظيفتها.\n يتم عادة إجراء العملية تحت التخدير الموضعي ولديها نسبة نجاح عالية.\n",
            2: "ابتسامة هوليوود",
            text2:
              "ابتسامة هوليوود هي إجراء تجميلي للأسنان يتضمن مجموعة من العلاجات مثل تبييض الأسنان وتجميل الأسنان بالخزف وأحيانًا زرع الأسنان لتحسين مظهر الأسنان وإنشاء ابتسامة جميلة وبيضاء ومثالية.\n يستوحى هذا الإجراء من ابتسامات هوليوود الساطعة والخالية من العيوب ويهدف إلى تعزيز الجمال الطبيعي لأسنان الشخص.\n إنه إجراء تجميلي شائع يمكن أن يساعد في زيادة الثقة بالنفس وتعزيز الثقة بالنفس.\n",
          },
          HealthTourismPage: {
            header1: "لماذا تركيا؟",
            text1:
              "تحتل تركيا موقعًا فريدًا يربط بين قارتي أوروبا وآسيا.\n يفضل الملايين من الناس تركيا كوجهة سياحية كل عام.\n يمكنك الوصول إلى تركيا في وقت قصير جدًا عن طريق الطائرة من العديد من البلدان.\n",
            header2: "احصل على رعاية صحية عالية الجودة بأسعار معقولة...\n",
            text2:
              "أصبحت تركيا بلدًا رائدًا في العالم الطبي بأطبائها المتخصصين دوليًا ومستشفياتها الحديثة المجهزة بأحدث التقنيات. تحتل المرتبة الثانية في العالم بأكثر من 50 مستشفى حاصل على اعتماد JCI.\n بالإضافة إلى ذلك، منذ وصولك إلى البلاد، ستتلقى جميع أنواع الخدمات التي قد تحتاجها في مستشفياتنا الحديثة بفريق أطباء متخصصين وبضمان شركة روموي.\n ",
            header3: "تركيا البلد المفضل في السياحة الصحية",
            text3:
              "تركيا هي بلد يربط بين قارتي أوروبا وآسيا، وكانت مهدًا للحضارات والثقافات على مر القرون. إنها جغرافيا غنية جدًا بجبالها وكهوفها وبحارها، والكثير من الجمال الطبيعي والتراث التاريخي الذي جلبته الحضارات المختلفة ذات الآلاف من سنوات التاريخ.\n",
            header4: "الثروات الثقافية والتاريخية",
            text4:
              "من الشرق إلى الغرب، من الشمال إلى الجنوب، كل منطقة وكل أرض لها كنز مختلف وثراء مختلف في كل زاوية، تركيا تقريبًا هي متحف في الهواء الطلق.\n استضافت عشرات الحضارات مثل سومر، آشور، الحيثيين، الإغريق، الليديين، الكلت، الفرس، الرومان، الرومان الشرقيون (البيزنطيون)، السلاجقة، المغول والعثمانيين.\n",
          },
          contactUsPage: {
            header: "اتصل بنا",
            SubmitQuestions: "أرسل",
            Questions: "أسئلتك",
            checkServices: "تحقق من خدماتنا",
            askQuestions: "اطرح أسئلتك هنا",
          },
          aboutUsPage: {
            header1: "من نحن",
            text1:
              "مرحبًا بك في\n شركة نديم أورنج للسياحة الصحية في اسطنبول! نحن متخصصون في مجموعة من علاجات التجميل، بما في ذلك:",
            1: "علاج وزراعة الشعر",
            2: "تشكيل الجسم وعلاجات البشرة",
            3: "عمليات التجميل الجراحية",
            4: "جماليات الأسنان وعلاجاتها",
            text2:
              "يتفانى فريقنا من المتخصصين ذوي الخبرة في تقديم خدمات عالية الجودة ورعاية شخصية لكل عميل من عملائنا.\n نحن نستخدم فقط أفضل المنتجات والتقنيات لضمان أن تغادر عيادتنا وأنت تشعر وتبدين بأفضل حالاتك.",
            text3: "زرنا اليوم أو احصل على استشارة مجانية",
            header2: "شركاؤنا",
            11: "عيادة إيستسيريوس",
            text11:
              "في عيادتنا الشركاتية إيستسيريوس، حيث نقدم أحدث تقنيات زراعة الشعر وعلاجات البشرة.\n\n تضم عيادتنا فريقًا من المحترفين ذوي الكفاءة العالية والخبرة الواسعة الذين يلتزمون بتقديم أفضل رعاية ممكنة لمرضانا. \n\n نحن ندرك أن تساقط الشعر ومشاكل البشرة يمكن أن تكون مصدر قلق كبير للعديد من الأشخاص، ولهذا السبب نقدم مجموعة من العلاجات المصممة للتعامل مع هذه المشاكل. \n\n تستخدم خدمات زراعة الشعر لدينا أحدث التقنيات، بما في ذلك استخراج وحدة الشعر (FUE) وزراعة وحدة الشعر (FUT)، لضمان تحقيق نتائج طبيعية ودائمة لمرضانا.\n\n بالإضافة إلى خدمات زراعة الشعر، نقدم أيضًا مجموعة متنوعة من علاجات البشرة، بما في ذلك تجديد البشرة بالليزر والتقشير الكيميائي والميكرو ديرمابرازيون.\n\n تم تصميم هذه العلاجات للتعامل مع مجموعة من مشاكل البشرة، بدءًا من حب الشباب والضرر الناتج عن أشعة الشمس وصولاً إلى التجاعيد وبقع العمر. في عيادتنا، نؤمن بأن كل مريض يستحق رعاية شخصية، ولذلك نستغرق الوقت للتعرف على كل فرد وتصميم العلاجات وفقًا لاحتياجاته الفريدة.\n\n هدفنا هو مساعدة مرضانا في الشعور بالثقة والراحة في بشرتهم الخاصة، ونحن ملتزمون بتحقيق ذلك من خلال علاجات آمنة وفعالة ومتقدمة.",
          },
          estesirius: {
            1: "Dr.Dilek Akın Aqrah Muyanehane",
            2: "Dr.Dilek Akın Aqrah Muyanehane",
            3: "في عيادتنا المشتركة إستيسيريوس، نقدم أحدث علاجات الشعر والبشرة.تضم العيادة أطباء متخصصين عاليي الكفاءة والخبرة ملتزمين بتوفير أفضل رعاية ممكنة لمرضانا.نحن ندرك أن فقدان الشعر ومشاكل الجلد يمكن أن تكون مصدر قلق كبير للعديد من الأشخاص، ولذلك نقدم مجموعة من العلاجات المصممة لمعالجة هذه المشاكل.تستخدم خدمات علاج الشعر لدينا أحدث التقنيات لضمان حصول مرضانا على نتائج طبيعية ودائمة.بالإضافة إلى خدمات علاج الشعر لدينا، نقدم أيضًا مجموعة متنوعة من علاجات الجلد، بما في ذلك إزالة الشعر بالليزر، وقشور الكيميائية، وتلميع الجلد.هذه العلاجات مصممة لمعالجة مجموعة من مشاكل الجلد، من حب الشباب والأضرار الناجمة عن الشمس إلى التجاعيد والبقع العمرية.نحن في عيادتنا نؤمن بأن كل مريض يستحق رعاية شخصية، ولهذا السبب نأخذ الوقت الكافي للتعرف على كل فرد ونضبط علاجاتنا لتلائم احتياجاتهم الفريدة.هدفنا هو مساعدة مرضانا على الشعور بالثقة والراحة في بشرتهم، ونحن ملتزمون بتحقيق ذلك من خلال علاجات آمنة وفعالة وحديثة.",
            4: "أفضل خدماتنا",
            5: "ميزوثيرابي",
            6: "حشو الوجه",
            7: "شد الوجه غير الجراحي",
            8: "PRP",
            9: "مكياج دائم",
            10: "بلكسر",
            11: "العناية بالبشرة",
            12: "علاج الخلايا الجذعية",
            13: "بوتوكس",
            14: "اتصل عيادة Dr.Dilek Akın Aqrah",
            15: "قم بزيارة موقع Dr.Dilek Akın Aqrah Clinic",
            16: "تحقق من خدمات زراعة الشعر لدينا ",
          },
          dentalcorperation: {
            1: "الخاصة بصحة الفم و الاسنان Avangart عيادة",
            2: "Avangart عيادة",
            3: "تقدم عيادة أفانجارت للصحة الفموية والأسنان خدماتها في مجمع مكة دي كوي بمدينة إسطنبول.\n يتم التصوير الشعاعي للمرضى باستخدام جهاز الأشعة السينية البانورامية في عيادتنا.\n تتمتع عيادة أفانجارت للصحة الفموية والأسنان بالتفويض للمشاركة في أنشطة السياحة الصحية الدولية، بالامتثال لمعايير الكفاءة الصحية لمنشآت السياحة الصحية الدولية التابعة لوزارة الصحة التركية والمبادئ العامة للوزارة.\n تجرى العلاجات وفقًا لظروف الجائحة.\n يولى اهتمامًا بالغًا لقواعد النظافة.\n يتم إجراء جميع العلاجات الأسنان الأخرى في العيادة، باستثناء العلاجات الأرثونتية والأسنان اللبنية.\n",
            4: "أفضل خدماتنا",
            5: "ابتسامة هوليوود (جمال الابتسامة)",
            6: "زراعة الأسنان",
            7: "علاج قنوات الجذر",
            8: "تبييض الأسنان",
            9: "الأسنان الاصطناعية",
            10: "خلع الأسنان",
            11: "قم بزيارة صفحة Instagram عيادة Avangart",
            12: "زيارة موقع عيادة أفانغارت",
            13: "تحقق من خدمات طب الأسنان لدينا",
          },
          plasticClinic: {
            1: "الدكتور صلاح الدين تولاني",
            2: "عيادة الدكتور صلاح الدين تولاني التجميلية",
            3: "في عام 2001 ، تم تعيينه للعمل في قسم الجراحة التجميلية والترميمية في مجموعة مستشفيات يونيفرسال (UGH)، حيث استمر في ممارسة مهنته. مجال الجراحة التجميلية لسنوات عديدة. \n الدكتور صلاح الدين تولوناي هو عضو في الجمعية الطبية التركية \n وهو أيضًا عضو في جمعية الجراحة التجميلية والترميمية التركية. حصل على الجائزة في المسابقة. أفضل جراح تجميل لهذا العام. الذي عقد في عام 2012. يواصل دراساته وأبحاثه في عيادته ومستشفى شريك. حصل مرتين على الجائزة في مسابقة أفضل أخصائي زراعة شعر للعام 2014 و 2015. \n الدكتور صلاح الدين تولوني يتحدث اللغة الإنجليزية بطلاقة. يسعى الدكتور تولوني ، الذي لديه العديد من المنشورات الطبية المحلية والدولية ، إلى مواكبة التطورات من خلال حضور المؤتمرات. بالإضافة إلى ذلك ، يتابع عن كثب ويشارك في المؤتمرات والندوات التي تعقد في الداخل والخارج.\n",
            4: "متخصصة في::",
            5: "الجراحات التجميلية",
            6: "زراعة الشعر",
            7: "جماليات طبية / إجراءات غير جراحية",
            8: "الجراحات الترميمية",
            9: "اتصل بالدكتور صلاح الدين",
            10: "قم بزيارة موقع الدكتور صلاح الدين ",
            11: "تحقق من خدمات عمليات التجميل لدينا",
          },
        },
      },
    },
  });

export default i18n;
